import { Box, Flex, Heading, Image, Link, Text } from '@chakra-ui/react';

import { BestBuyoutRates, EquityLeasedCar } from '../../../assets/Images';
import { FIVE_FEES, FOUR_BEFORE } from '../../../constants/urls';
import FrequentQuestions from './FrequentQuestions';

const Faq = () => {
  return (
    <Box px={{ base: '5%', md: '15%' }} m="50px auto 0">
      <Heading fontWeight="semibold" fontSize={{ base: 25, md: 35 }} textAlign="center" pb="20px">
        Got Questions? <Text as="br" display={{ md: 'none' }} />
        We have answers.
      </Heading>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="center"
        m="0 auto 50px auto"
        maxW="1200px"
      >
        <Flex flexDir="column" w={{ base: '100%', md: '55%' }} mr={{ base: 'unset', md: 8 }}>
          <Text fontSize="22px" fontWeight="light" textAlign={{ base: 'center', md: 'left' }}>
            Frequently Asked Questions
          </Text>
          <FrequentQuestions />
        </Flex>
        <Flex
          flexDir="column"
          alignItems="center"
          w={{ base: '100%', md: '40%' }}
          mt={{ base: '25px', md: 'unset' }}
        >
          <Text fontSize="22px" fontWeight="light" textAlign={{ base: 'center', md: 'left' }}>
            Top articles from our Knowledge Center
          </Text>
          <Box w={{ base: '100%', md: '80%' }} mt={4}>
            <Link isExternal href={FOUR_BEFORE}>
              <Image
                src={EquityLeasedCar}
                alt="4 Things to Know About Equity and Your Leased Car"
              />
              <Box m={1}>4 Things to Know About Equity and Your Leased Car</Box>
            </Link>
          </Box>
          <Box w={{ base: '100%', md: '80%' }} mt={4}>
            <Link isExternal href={FIVE_FEES}>
              <Image
                src={BestBuyoutRates}
                alt="Best Lease Buyout Loan Rates (And Where to Find Them)"
              />
              <Box m={1}>Best Lease Buyout Loan Rates (And Where to Find Them)</Box>
            </Link>
          </Box>
          {/* <Box w={{ base: '100%', md: '80%' }} mt={4}>
            <Link isExternal href={RESIDUAL_VALUE_QUICK}>
              <Image src={ResidualValue} alt="A Quick Guide to Residual Value in Leasing" />
              <Box m="1px 0px">A Quick Guide to Residual Value in Leasing</Box>
            </Link>
          </Box> */}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Faq;
