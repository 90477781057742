import { Box, Flex, Image, Text } from '@chakra-ui/react';

import { Endorsement89Chart, EndorsementsNoBull } from '../../assets/Images';
import Widget from '../../components/LicensePlateOrVinInputEndorsement';
import EndorsementHeading from './EndorsementHeading';
import EndorsementParagraph from './EndorsementParagraph';
import EndorsementVideo from './EndorsementVideo';
import EndorsementsSubnav from './EndorsementsSubnav';
import EndorsementsSubnavMobile from './EndorsementsSubnavMobile';

const EndorsementSavedTime = () => {
  return (
    <>
      <EndorsementsSubnavMobile />
      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={{
          base: 0,
          md: 4,
        }}
      >
        <EndorsementsSubnav label="skip the dealer" url="no-dealer" color="#3195ab" />
        <Flex
          bg="#012d5d"
          w={{
            base: '100%',
            lg: '60%',
          }}
          flexDir="column"
          py={10}
          ps={10}
          pe={5}
          pos="relative"
          zIndex={1}
        >
          <EndorsementHeading
            heading="They found the process fast and convenient"
            color="#F4C53E"
            textAlign="left"
            mt={16}
          />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '40%',
          }}
        >
          <EndorsementVideo name="Montage-Convenience" />
        </Flex>
        <EndorsementsSubnav label="exceptional service" url="experts" color="#599754" />
        <EndorsementsSubnav label="save money" url="saved-money" color="#685495" />
      </Flex>

      <Flex
        flexDir={{
          base: 'column-reverse',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={8}
      >
        <Flex
          justifyContent="center"
          w={{
            base: '100%',
            lg: '60%',
          }}
          mb={{
            base: '16px',
            lg: '48px',
          }}
          mt={{
            base: 0,
            lg: '-48px',
          }}
          pos="relative"
          zIndex={2}
        >
          <Box
            w={{
              base: '100%',
              lg: '75%',
            }}
            bg="#FFF"
            p={3}
            borderRadius={8}
            boxShadow="0 0 10px rgba(0,0,0,0.18)"
            mt={{
              base: 8,
              lg: 0,
            }}
          >
            <Text fontSize="13px" color="#676767" textTransform="uppercase" fontWeight={700}>
              Get started with your License Plate or VIN
            </Text>
            <Widget btnLabel="SKIP THE DEALERSHIP" />
          </Box>
        </Flex>
      </Flex>

      <Flex
        justifyContent="center"
        flex={1}
        w="100%"
        gap={8}
        py={{
          base: 5,
          lg: 10,
        }}
        borderBottomWidth={1}
        borderTopWidth={1}
        flexDir={{
          base: 'column',
        }}
      >
        <Box mx="auto" w="75%">
          <EndorsementHeading
            color="#f4c53e"
            heading="Expert Fast."
            textAlign={{
              base: 'center',
            }}
            lineHeight="72px"
          />
          <EndorsementParagraph
            fontWeight={500}
            textAlign="center"
            paragraph="Lease End saved these drivers time by handling the lease ending process in a quick and stress-free way."
          />
        </Box>
      </Flex>

      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        gap={8}
        bg="#087dc5"
        justifyContent="center"
        py={16}
      >
        <Image src={EndorsementsNoBull} w="50%" />
      </Flex>

      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        bg="#F4C53E"
      >
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
        >
          <EndorsementVideo name="Benjamin-Convenience" />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
          p={8}
        >
          <EndorsementHeading
            fontSize="48px"
            lineHeight="52px"
            heading="from application to loan in less than...oh wow, it's already done?!"
            textAlign="left"
          />
          <EndorsementParagraph
            fontWeight={600}
            fontSize={16}
            paragraph="At Lease End's cutting-edge tech makes loan approval a breeze. Buying out your lease yourself could take weeks, but most Lease End customers secure a loan in under two days! With our tech doing the heavy lifting, getting approved is so easy you might even have time to finally binge that show everyone's talking about."
          />
          <Flex flexDir="column" flex={1} w="100%" gap={4} py={8} bg="#F4C53E">
            <Flex flex={1} gap={4}>
              <Image src={Endorsement89Chart} w="80px" />
              <Flex flexDir="column" alignItems="flex-start" gap={1} justifyContent="center">
                <Text fontSize={20} textTransform="uppercase" fontWeight={800} textAlign="left">
                  save time with lease end
                </Text>
                <Text fontSize={16} textAlign="left">
                  89% of customers have their car loan secured in less than a day.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default EndorsementSavedTime;
