import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  AccordionProps,
  Box,
  Flex,
  Image,
} from '@chakra-ui/react';
import React from 'react';

import { NoDealership, OverMileage, PiggyBank, VehicleDamage } from '../../../../assets/Images';
import { damageText, dmvText, equityText, mileageText } from '../../utils';

interface ItemProps extends AccordionItemProps {
  title: string;
  children: React.ReactNode;
}

const Item: React.FC<ItemProps> = ({ title, children, ...props }) => {
  return (
    <AccordionItem
      borderWidth="1px"
      borderColor="grayBackground"
      rounded="lg"
      bgColor="white"
      {...props}
    >
      <AccordionButton
        bg="white"
        borderRadius="5px"
        _expanded={{ bg: 'white', borderRadius: '5px 5px 0 0' }}
        _focus={{ bg: 'white', borderRadius: '5px' }}
        _hover={{ bg: 'white', borderRadius: '5px' }}
      >
        <Box flex="1" textAlign="center" fontSize={18} p={2} fontFamily="Monorale" fontWeight={600}>
          {title}
        </Box>
      </AccordionButton>
      <AccordionPanel pl={2} pt={0} mx={6}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

interface MobileHelpCardsProps extends AccordionProps {
  _accordionItem?: AccordionItemProps;
}

const MobileHelpCards = ({ _accordionItem, ...props }: MobileHelpCardsProps) => {
  return (
    <Accordion allowToggle mt={4} maxW={{ base: 'none', lg: '680px' }} {...props}>
      <Flex flexDir="column" gap="7.5px">
        <Item title="I don't want to go to the dealership or DMV." {..._accordionItem}>
          <Image
            src={NoDealership}
            m="15px auto"
            alt="I don't want to go to the dealership or DMV"
          />
          <Box>{dmvText}</Box>
        </Item>
        <Item title="I want to keep my car's equity.">
          <Image src={PiggyBank} m="15px auto" alt="I want to keep my car's equity." />
          <Box>{equityText}</Box>
        </Item>
        <Item title="I'm over my mileage limit.">
          <Image src={OverMileage} m="15px auto" alt="I'm over my mileage limit." maxW="225px" />
          <Box>{mileageText}</Box>
        </Item>
        <Item title="My car is damaged.">
          <Image src={VehicleDamage} m="15px auto" alt="My car is damaged." />
          <Box>{damageText}</Box>
        </Item>
      </Flex>
    </Accordion>
  );
};

export default MobileHelpCards;
