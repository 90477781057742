import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { ENDORSEMENTS } from '../../constants/urls';

interface EndorsementsSubnavProps extends FlexProps {
  label: string | ReactNode;
  url: string;
  color: string;
  mobile?: boolean;
}

const EndorsementsSubnav = ({
  label,
  url,
  color,
  mobile = false,
  ...rest
}: EndorsementsSubnavProps) => {
  const { pathname } = useLocation();
  const jump = () => {
    window.location.href = `${ENDORSEMENTS}/${url}`;
  };
  return mobile ? (
    <>
      <Flex
        onClick={() => jump()}
        display={{
          base: 'flex',
          md: 'none',
        }}
        color="white"
        bg={color}
        {...rest}
        justifyContent="center"
        fontWeight={600}
        fontSize={12}
        textTransform="uppercase"
        py={4}
        opacity={pathname === `${ENDORSEMENTS}/${url}` ? '1' : '0.9'}
        _hover={{
          opacity: '1 !important',
        }}
      >
        <Text textAlign="center">{label}</Text>
      </Flex>
    </>
  ) : (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        w="60px"
        height="100%"
        bg={color}
        css={{
          transition: '200ms all ease-in-out',
          opacity: '0.95',
        }}
        _hover={{
          opacity: 1,
        }}
        cursor="pointer"
        onClick={() => jump()}
        display={{
          base: 'none',
          md: 'flex',
        }}
        {...rest}
      >
        <Text
          color="#FFF"
          textTransform="uppercase"
          fontSize={20}
          fontWeight={700}
          css={{
            writingMode: 'vertical-lr',
            transition: '200ms all ease-in-out',
          }}
        >
          {label}
        </Text>
      </Flex>
    </>
  );
};

export default EndorsementsSubnav;
