/* eslint-disable array-callback-return */

/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable no-restricted-syntax */

/* eslint-disable security/detect-non-literal-regexp */
import { Flex, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';

import Description from '../../components/Description';
import Title from '../../components/Title';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { BLOG_SANITY } from '../../constants/urls';
import { BlogSearchTerm } from '../../helpers/Atom';
import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { BlogPostProps } from '../Sanity/Model';
import Author from './Author';
import TagLink from './TagLink';

/* eslint-disable react/destructuring-assignment */

interface PostProps {
  post: BlogPostProps;
}

const Result = (props: PostProps) => {
  const history = useHistory();
  const [blogSearchTerm] = useAtom(BlogSearchTerm);

  const highlightTerm = (text: string, term: string | null) => {
    if (!term) {
      return text;
    }
    const regex = new RegExp(term);
    const textArray = text.split(regex);
    return textArray.map((l, i) => (
      <>
        {l}
        {i < textArray.length - 1 ? (
          <Text as="span" background="#fff600" color="#000" borderRadius={2}>
            {term}
          </Text>
        ) : (
          ''
        )}
      </>
    ));
  };
  return (
    <>
      <Flex
        flexDirection="column"
        mb={{
          base: '15px',
          lg: '30px',
        }}
        pt={{
          base: '15px',
          lg: '30px',
        }}
        mx="auto"
        borderTopWidth={{
          base: 1,
        }}
        borderRadius={8}
      >
        <Title
          textTransform="capitalize"
          textAlign="left"
          fontSize={{
            base: '16pt',
            lg: '20pt',
          }}
          cursor="pointer"
          onClick={() => history.push(`${BLOG_SANITY}/${props.post.slug.current}`)}
        >
          {highlightTerm(props.post.title, blogSearchTerm)}
        </Title>
        <Author post={props.post} showDate />
        {props.post.mainImage && (
          <Flex
            mb={3}
            w="100%"
            justifyContent="space-between"
            gap={8}
            flexDir={{
              base: 'column',
              md: 'row',
            }}
          >
            <Flex
              width={{
                base: '100%',
                md: '25%',
              }}
            >
              <BlogImageComponent value={props.post.mainImage} isInline={false} />
            </Flex>
            {props.post.snippet && (
              <Flex flex={1}>
                <Description textAlign="left" fontSize={20}>
                  {highlightTerm(props.post.snippet, blogSearchTerm)}
                </Description>
              </Flex>
            )}
          </Flex>
        )}
        {props.post.categories && props.post.categories.length && (
          <>
            <Flex
              mt={4}
              mb={4}
              alignItems="flex-start"
              justifyContent="flex-start"
              flexWrap="wrap"
              gap={1}
            >
              {props.post.categories.map((category: string) => {
                return (
                  <TagLink
                    tag={category}
                    searchMatch={
                      blogSearchTerm?.toLocaleLowerCase() === category.toLocaleLowerCase()
                    }
                  />
                );
              })}
            </Flex>
          </>
        )}
        <Flex>
          <PrimaryButton onClick={() => history.push(`${BLOG_SANITY}/${props.post.slug.current}`)}>
            READ ARTICLE
          </PrimaryButton>
        </Flex>
      </Flex>
    </>
  );
};

export default Result;
