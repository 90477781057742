import { BLOG_SANITY } from '../constants/urls';
import { Maybe, TemporaryData } from '../gql/generated/graphql';

export const getTimezoneStr = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const isDateBetween = (date: Date, lowerDate: Date, upperDate: Date) => {
  const dateTime = date.getTime();
  return lowerDate.getTime() <= dateTime && dateTime <= upperDate.getTime();
};

export const camelCaseToSpaceSeparated = (string: string) => {
  let result = string
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([A-Z][a-z])/g, '$1')
    .replace('  ', ' ')
    .trim();

  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
};

export const snakeCaseToSpaceSeparated = (input: string): string => input.replace(/_/g, ' ');

export const titleCase = (string: string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const getAge = (birthDate: Date) => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export const formatMileage = (value: Maybe<number>) =>
  value?.toLocaleString('en-US', { minimumFractionDigits: 0 }) || '';

export const formatMoney = (
  value: Maybe<number>,
  options: { noCents: boolean } = { noCents: false },
): string =>
  typeof value === 'number'
    ? value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: options.noCents ? 0 : 2,
        maximumFractionDigits: options.noCents ? 0 : 2,
      })
    : '';

export const isNullOrUndefined = (val: unknown) => val === null || val === undefined;

export const tempInfoHasDecodedCar = (decoded: Maybe<TemporaryData>): boolean =>
  !!decoded?.year && !!decoded?.make && !!decoded?.model && !!decoded?.vin;

export const pluralize = (word: string, count: number, suffix = 's') =>
  count === 1 ? word : `${word}${suffix}`;

export const getLongStateName = (st: string) => {
  switch (st) {
    case 'AL':
      return 'ALABAMA';

    case 'AK':
      return 'ALASKA';

    case 'AS':
      return 'AMERICAN SAMOA';

    case 'AZ':
      return 'ARIZONA';

    case 'AR':
      return 'ARKANSAS';

    case 'CA':
      return 'CALIFORNIA';

    case 'CO':
      return 'COLORADO';

    case 'CT':
      return 'CONNECTICUT';

    case 'DE':
      return 'DELAWARE';

    case 'DC':
      return 'DISTRICT OF COLUMBIA';

    case 'FL':
      return 'FLORIDA';

    case 'GA':
      return 'GEORGIA';

    case 'GU':
      return 'GUAM';

    case 'HI':
      return 'HAWAII';

    case 'ID':
      return 'IDAHO';

    case 'IL':
      return 'ILLINOIS';

    case 'IN':
      return 'INDIANA';

    case 'IA':
      return 'IOWA';

    case 'KS':
      return 'KANSAS';

    case 'KY':
      return 'KENTUCKY';

    case 'LA':
      return 'LOUISIANA';

    case 'ME':
      return 'MAINE';

    case 'MH':
      return 'MARSHALL ISLANDS';

    case 'MD':
      return 'MARYLAND';

    case 'MA':
      return 'MASSACHUSETTS';

    case 'MI':
      return 'MICHIGAN';

    case 'MN':
      return 'MINNESOTA';

    case 'MS':
      return 'MISSISSIPPI';

    case 'MO':
      return 'MISSOURI';

    case 'MT':
      return 'MONTANA';

    case 'NE':
      return 'NEBRASKA';

    case 'NV':
      return 'NEVADA';

    case 'NH':
      return 'NEW HAMPSHIRE';

    case 'NJ':
      return 'NEW JERSEY';

    case 'NM':
      return 'NEW MEXICO';

    case 'NY':
      return 'NEW YORK';

    case 'NC':
      return 'NORTH CAROLINA';

    case 'ND':
      return 'NORTH DAKOTA';

    case 'MP':
      return 'NORTHERN MARIANA ISLANDS';

    case 'OH':
      return 'OHIO';

    case 'OK':
      return 'OKLAHOMA';

    case 'OR':
      return 'OREGON';

    case 'PW':
      return 'PALAU';

    case 'PA':
      return 'PENNSYLVANIA';

    case 'PR':
      return 'PUERTO RICO';

    case 'RI':
      return 'RHODE ISLAND';

    case 'SC':
      return 'SOUTH CAROLINA';

    case 'SD':
      return 'SOUTH DAKOTA';

    case 'TN':
      return 'TENNESSEE';

    case 'TX':
      return 'TEXAS';

    case 'UT':
      return 'UTAH';

    case 'VT':
      return 'VERMONT';

    case 'VI':
      return 'VIRGIN ISLANDS';

    case 'VA':
      return 'VIRGINIA';

    case 'WA':
      return 'WASHINGTON';

    case 'WV':
      return 'WEST VIRGINIA';

    case 'WI':
      return 'WISCONSIN';

    case 'WY':
      return 'WYOMING';
    default:
      return '';
  }
};

export const abbreviateMonth = (month: string): string => {
  const months = {
    january: 'Jan.',
    february: 'Feb.',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'Aug.',
    september: 'Sept.',
    october: 'Oct.',
    november: 'Nov.',
    december: 'Dec.',
  };

  return months[month.toLowerCase() as keyof typeof months] || month;
};

export const isBooleanSetAndFalse = (val: unknown) => typeof val === 'boolean' && !val;

export const blogJump = (slug: string) => {
  window.location.href = `${BLOG_SANITY}/${slug}`;
};
