import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Router } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { ThemeProvider } from 'styled-components';

import App from './App';
import ScrollToTop from './ScrollToTop';
import customTheme from './chakra';
import ReCaptcha from './components/ReCaptcha';
import Fonts from './components/ui/Fonts';
import config, { isProd } from './config';
import browserHistory from './history';
import ErrorPage from './pages/ErrorPage';
import AuthorizedApolloProvider from './providers/AuthorizedApolloProvider';
import { initSentry } from './services/sentry';
import { RudderEvent, rudderanalytics } from './utils/rudderstack';
import { theme } from './utils/styles/theme';

if (isProd && window.location.pathname !== '/') {
  const tagManagerArgs = {
    gtmId: config.gtm.gtmId,
    auth: config.gtm.auth,
    preview: config.gtm.preview,
  };

  TagManager.initialize(tagManagerArgs);
}

const { ToastContainer } = createStandaloneToast({
  theme: customTheme,
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (isProd && window.location.pathname !== '/') {
  initSentry();
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarklyClientId,
    context: {
      kind: 'user',
      anonymous: true,
    },
    options: {
      streaming: false,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  const onError = (e: Error) => {
    if (!isProd) {
      // console.log(e);
    } else {
      rudderanalytics.track(RudderEvent.Error, {
        error_message: e.message,
        pathname: window.location.pathname,
      });
    }
  };

  root.render(
    <React.StrictMode>
      <ErrorBoundary fallback={ErrorPage} onError={onError}>
        <Suspense>
          <Router history={browserHistory}>
            <AuthorizedApolloProvider>
              <LDProvider>
                <ThemeProvider theme={theme}>
                  <ChakraProvider theme={customTheme}>
                    <Fonts />
                    <ScrollToTop />
                    {isProd && window.location.pathname !== '/' && <ReCaptcha />}
                    <App />
                    <ToastContainer />
                  </ChakraProvider>
                </ThemeProvider>
              </LDProvider>
            </AuthorizedApolloProvider>
          </Router>
        </Suspense>
      </ErrorBoundary>
    </React.StrictMode>,
  );
})();
