import { Box, Flex, Image, Text } from '@chakra-ui/react';

import {
  Endorsements12Min,
  Endorsements17x,
  Endorsements76perc,
  Endorsements84perc,
  Endorsements86perc,
  SingleQuote,
} from '../../assets/Images';
import Widget from '../../components/LicensePlateOrVinInputEndorsement';
import EndorsementHeading from './EndorsementHeading';
import EndorsementParagraph from './EndorsementParagraph';
import EndorsementVideo from './EndorsementVideo';
import EndorsementsSubnav from './EndorsementsSubnav';
import EndorsementsSubnavMobile from './EndorsementsSubnavMobile';

const EndorsementSkip = () => {
  return (
    <>
      <EndorsementsSubnavMobile />
      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={{
          base: 0,
          md: 4,
        }}
      >
        <Flex
          bg="#5093A7"
          w={{
            base: '100%',
            lg: '60%',
          }}
          flexDir="column"
          py={10}
          ps={10}
          pe={5}
          pos="relative"
          zIndex={1}
        >
          <EndorsementHeading
            heading="Skipped the dealer"
            color="#F4C53E"
            textAlign="left"
            mt={16}
          />
          <EndorsementParagraph
            color="white"
            paragraph="These drivers skipped the hassle of pushy dealerships by working with Lease End. From finding the best financing options to getting your new car's plates, Lease End handles all the details. Click play to hear how buying out your lease can be a simple, stress-free process you can do from home. "
          />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '40%',
          }}
        >
          <EndorsementVideo name="Montage-NoDealership" />
        </Flex>
        <EndorsementsSubnav label="save time" url="saved-time" color="#032d5b" />
        <EndorsementsSubnav label="exceptional service" url="experts" color="#599754" />
        <EndorsementsSubnav label="save money" url="saved-money" color="#685495" />
      </Flex>

      <Flex
        flexDir={{
          base: 'column-reverse',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={8}
      >
        <Flex
          justifyContent="center"
          w={{
            base: '100%',
            lg: '60%',
          }}
          mb={{
            base: '16px',
            lg: '48px',
          }}
          mt={{
            base: 0,
            lg: '-48px',
          }}
          pos="relative"
          zIndex={2}
        >
          <Box
            w={{
              base: '100%',
              lg: '75%',
            }}
            bg="#FFF"
            p={3}
            borderRadius={8}
            boxShadow="0 0 10px rgba(0,0,0,0.18)"
            mt={{
              base: 8,
              lg: 0,
            }}
          >
            <Text fontSize="13px" color="#676767" textTransform="uppercase" fontWeight={700}>
              Get started with your License Plate or VIN
            </Text>
            <Widget btnLabel="SKIP THE DEALERSHIP" />
          </Box>
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '40%',
          }}
          pos="relative"
        >
          <Image
            src={SingleQuote}
            pos="absolute"
            w="48px"
            top="-16px"
            zIndex={1}
            opacity="0.1"
            left="-16px"
          />
          <Text
            fontSize={18}
            fontStyle="italic"
            me={{
              base: 0,
              lg: 16,
            }}
            ms={{
              base: 4,
              lg: 0,
            }}
            mt={4}
            textAlign="left"
            pos="relative"
            zIndex={2}
            lineHeight={1.54}
          >
            From the first phone call to a signed contract took less than three hours. The process
            was a breeze. Thanks for saving me the pain of having to go to the dealership (with
            their hidden fees and high-pressure sales tactics.)
          </Text>
        </Flex>
      </Flex>

      <Flex
        justifyContent="center"
        flex={1}
        w="100%"
        gap={8}
        borderBottomWidth={1}
        borderTopWidth={1}
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
      >
        <Flex
          w={{
            base: '100%',
            lg: 325,
          }}
          bg="#5093A7"
          alignItems="center"
          ps={8}
        >
          <EndorsementHeading
            color="#fff"
            heading="WE saved them time"
            textAlign={{
              base: 'center',
              lg: 'left',
            }}
            lineHeight="72px"
          />
        </Flex>
        <Flex
          flex={1}
          flexDir="column"
          alignItems="center"
          py={{
            base: 5,
            lg: 10,
          }}
        >
          <Text
            fontSize={24}
            color="#002D5D"
            textTransform="uppercase"
            textAlign="center"
            fontWeight={800}
            mb={4}
          >
            Lease End
            <Text as="br" />
            takes 12 minutes
          </Text>
          <Image w="100" maxW={200} src={Endorsements12Min} />
        </Flex>
        <Flex
          flex={1}
          borderStartWidth={{
            base: 0,
            lg: 1,
          }}
          px={8}
          flexDir="column"
          alignItems="center"
          py={{
            base: 5,
            lg: 10,
          }}
        >
          <Text
            fontSize={24}
            color="#002D5D"
            textTransform="uppercase"
            textAlign="center"
            fontWeight={800}
            mb={4}
          >
            The Dealership
            <Text as="br" />
            takes 3.5+ hours!
          </Text>
          <Image w="100%" src={Endorsements17x} mb={4} />
          <Text fontSize="12px" color="#787878" textAlign="left">
            * According to J.D. Power’s U.S. Sales Satisfaction Index report.
          </Text>
        </Flex>
      </Flex>

      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={{
          base: 8,
          lg: 16,
        }}
        gap={8}
      >
        <Flex
          w={{
            base: '100%',
            lg: '60%',
          }}
        >
          <EndorsementParagraph
            pt={{
              base: 0,
              lg: 4,
            }}
            pb={{
              base: 4,
              lg: 0,
            }}
            pe={8}
            fontSize={22}
            lineHeight="32px"
            fontWeight={400}
            textAlign={{
              base: 'center',
              lg: 'left',
            }}
            paragraph="Spend less time in the dealership and more time with your car by buying out your lease
              with Lease End. If pushy dealerships are the only thing standing between you and your
              car's happily-ever-after, let Lease End be your matchmaker. We'll take care of the
              details while you start planning your road trips together!"
          />
        </Flex>
        <Flex
          bg="white"
          w={{
            base: '100%',
            lg: '40%',
          }}
          flexDir="column"
          mb={8}
        >
          <Box bg="#FFF" p={3} borderRadius={8} boxShadow="0 0 10px rgba(0,0,0,0.18)">
            <Text fontSize="13px" color="#676767" textTransform="uppercase" fontWeight={700}>
              Get started with your License Plate or VIN
            </Text>
            <Widget btnLabel="SAVE ME SOME TIME" />
          </Box>
        </Flex>
      </Flex>

      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        bg="#F4C53E"
        mt={8}
      >
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
        >
          <EndorsementVideo name="Glenn-NoDealer" />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
          p={8}
        >
          <EndorsementHeading
            heading="They avoided the runaround"
            textAlign="left"
            fontSize={40}
            lineHeight={1.2}
          />
          <EndorsementParagraph
            fontWeight={600}
            fontSize={16}
            paragraph="Americans don’t trust dealerships. Here’s some of the top concerns:"
          />
          <Flex flexDir="column" flex={1} w="100%" gap={2} py={4} bg="#F4C53E">
            <Flex flex={1} gap={4}>
              <Image src={Endorsements86perc} w="80px" />
              <Flex flexDir="column" alignItems="flex-start" gap={1} justifyContent="center">
                <Text fontSize={20} textTransform="uppercase" fontWeight={800} textAlign="left">
                  hidden fees
                </Text>
                <Text fontSize={16} textAlign="left">
                  86% of people are concerned about hidden fees when leasing a vehicle.
                </Text>
              </Flex>
            </Flex>
            <Flex flex={1} gap={4}>
              <Image src={Endorsements84perc} w="80px" />
              <Flex flexDir="column" alignItems="flex-start" gap={1} justifyContent="center">
                <Text fontSize={20} textTransform="uppercase" fontWeight={800} textAlign="left">
                  NO TRANSPARENCY
                </Text>
                <Text fontSize={16} textAlign="left">
                  84% of people say price transparency is lacking at most car dealerships.
                </Text>
              </Flex>
            </Flex>
            <Flex flex={1} gap={4}>
              <Image src={Endorsements76perc} w="80px" />
              <Flex flexDir="column" alignItems="flex-start" gap={1} justifyContent="center">
                <Text fontSize={20} textTransform="uppercase" fontWeight={800} textAlign="left">
                  DISHONEST PRICING
                </Text>
                <Text fontSize={16} textAlign="left">
                  76% Of Americans Don’t Trust Car Dealers To Be Honest About Pricing
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <EndorsementParagraph
            fontWeight={600}
            fontSize={16}
            p={0}
            m={0}
            paragraph="But here at Lease end, we believe buying a car shouldn’t be complicated—especially when it’s one you’ve already been driving. We make it easy to find the best loan by cutting through the confusion and showing you which bank offers the best deal. No hidden fees, just a focus on getting you the best offer possible while providing the best customer service."
          />
        </Flex>
      </Flex>
    </>
  );
};

export default EndorsementSkip;
