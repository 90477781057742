/* eslint-disable react/jsx-pascal-case */
import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';

import { CivicGuy, NoDealership, OverMileage, VehicleDamage } from '../../assets/Images';
import LicensePlateOrVinInput_LPSimple from '../../components/LicensePlateOrVinInput_LPSimple';
import Title from '../../components/Title';
import { fetchMarketingDeals } from '../../utils/globals';

interface BenefitProps {
  image: string;
  tag: string;
}

const Benefit = ({ image = '', tag = '' }: BenefitProps) => {
  return (
    <>
      <Flex
        flex={1}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        borderWidth={1}
        background="#f7f7f7"
        borderRadius={8}
        p={4}
        px={8}
        gap={3}
      >
        <Text textTransform="capitalize" fontSize={16} fontWeight={800} color="#000">
          {tag}
        </Text>
        <Image src={image} h="100px" />
      </Flex>
    </>
  );
};

const LPSimple = () => {
  return (
    <>
      <Container maxW="1024px" my={4} pb={16}>
        <Flex w="100%" alignItems="flex-start" justifyContent="space-between">
          <Flex
            flexDir="column"
            justifyContent={{
              base: 'center',
              md: 'flex-start',
            }}
            flex={1}
            alignItems="flex-start"
            mt={{
              base: 8,
              md: 0,
            }}
          >
            <Title
              fontSize={{
                base: 48,
                md: 56,
              }}
              lineHeight={1.2}
              fontWeight={800}
              color="#000"
              textAlign={{
                base: 'center',
                md: 'left',
              }}
              m={0}
              p={0}
            >
              End your lease, keep your car & avoid the dealership
            </Title>
            <Flex mt="32px">
              <Title
                textAlign={{
                  base: 'center',
                  md: 'left',
                }}
                color="#000"
                fontSize={{
                  base: 20,
                  md: 24,
                }}
                m={0}
                p={0}
                fontWeight={500}
                lineHeight={1.4}
              >
                We've helped more than{' '}
                <Text as="span" fontWeight={800}>
                  {fetchMarketingDeals(false)} people
                </Text>{' '}
                end their lease and keep their car!
              </Title>
            </Flex>
            <Box
              mt="32px"
              display={{
                base: 'none',
                md: 'block',
              }}
            >
              <Text mt="32px" fontSize={16} fontWeight={500}>
                End your car lease 100% online with Lease End – no haggling, no negotiating, and no
                extra fees. We take care of your titling, too, so you can skip the lines at the DMV.
              </Text>
              <Text pt="22px" fontSize={16} fontWeight={500}>
                Don’t get pushed into going to the dealership or DMV when you don’t have to.
              </Text>
            </Box>
            <Flex
              mt={{
                base: '32px',
                md: '48px',
              }}
              flexDir="row"
              flex={1}
              w="100%"
              alignItems="flex-start"
              gap={8}
            >
              <LicensePlateOrVinInput_LPSimple />
              <Image
                display={{
                  sm: 'none',
                  md: 'block',
                }}
                src={CivicGuy}
                w={{
                  sm: '0',
                  md: '350px',
                  lg: '450px',
                }}
                mt="-58px"
              />
            </Flex>
            <Flex
              display={{
                base: 'flex',
                md: 'none',
              }}
              w="100%"
              mt={8}
            >
              <Image src={CivicGuy} w="100%" />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          mt={{
            base: 8,
            md: 16,
          }}
          pt={{
            base: 8,
            md: 0,
          }}
          borderTopWidth={{
            base: 1,
            md: 0,
          }}
          justifyContent="space-between"
          gap={3}
          flexDir={{
            base: 'column-reverse',
            md: 'row',
          }}
        >
          <Benefit image={NoDealership} tag="No hassle at the dealership" />
          <Benefit image={OverMileage} tag="No fees for too many miles!" />
          <Benefit image={VehicleDamage} tag="No charge for damages!" />
        </Flex>
      </Container>
    </>
  );
};

export default LPSimple;
