/* eslint-disable react/no-array-index-key */
import { Box, Button, Flex, Image, Input, Link } from '@chakra-ui/react';
import { createBrowserHistory } from 'history';
import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { FaMagnifyingGlass, FaX } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';

import { LearnLogo } from '../../assets/Images';
import { BLOG_SANITY } from '../../constants/urls';
import { BlogSearchTerm } from '../../helpers/Atom';
import { blogMenuFetch } from '../Sanity/Data';
import { CategoryProps } from '../Sanity/Model';

interface NavLinkProps {
  x: number;
  tag: string;
  title: string;
  mobile?: boolean;
}

const NavLink = ({ x, tag, title, mobile = false }: NavLinkProps) => {
  const history = createBrowserHistory({ forceRefresh: true });
  const jump = (slug?: string, isNav?: boolean) => {
    if (isNav) {
      history.push(`${BLOG_SANITY}/tag/${slug}`);
      return;
    }
    history.push(`${BLOG_SANITY}/${slug}`);
  };
  return (
    <>
      <Link
        onClick={() => jump(title, true)}
        key={`le-nav-${x}`}
        fontSize={mobile ? 13 : 16}
        fontWeight={600}
        color="white"
        lineHeight="52px"
        borderRadius={4}
        backgroundColor={tag === title ? 'leaseEndBlue' : 'transparent'}
        _hover={{
          backgroundColor: tag === title ? 'leaseEndBlue' : 'rgba(255, 255, 255, 0.18)',
          color: 'white',
        }}
        flex={1}
      >
        {title}
      </Link>
    </>
  );
};

const Nav = () => {
  const [blogSearch, setBlogSearch] = useState<boolean>(false);
  const [, setBlogSearchTerm] = useAtom(BlogSearchTerm);
  const history = createBrowserHistory({ forceRefresh: true });
  const [nav, setNav] = useState<CategoryProps[] | null>(null);

  const searchRef = useRef<HTMLInputElement | null>(null);

  const handleSearch = (open: boolean) => {
    setBlogSearch(open);
  };

  const doSearch = () => {
    if (searchRef.current) {
      setBlogSearch(false);
      setBlogSearchTerm(searchRef.current.value);
    }
  };

  const fetchNav = async () => {
    const data: CategoryProps[] | null = await blogMenuFetch();
    setNav(data);
  };
  const params: {
    tag: string;
  } = useParams();
  const jump = (slug?: string, isNav?: boolean) => {
    if (isNav) {
      history.push(`${BLOG_SANITY}/tag/${slug}`);
      return;
    }
    history.push(`${BLOG_SANITY}/${slug}`);
  };
  useEffect(() => {
    fetchNav();
  }, []);
  return (
    <>
      <Box
        display={{
          base: 'block',
          md: 'none',
        }}
        mb={5}
        onClick={() => jump('', false)}
        cursor="pointer"
      >
        <Image src={LearnLogo} w={130} />
      </Box>
      <Flex flexDir="row" justifyContent="flex-start" alignItems="center" w="100%" gap={4}>
        <Flex
          w="100%"
          display={{
            base: 'none',
            md: 'flex',
          }}
          gap={3}
        >
          <Image
            onClick={() => jump('', false)}
            src={LearnLogo}
            w={220}
            display={{
              base: 'none',
              md: 'block',
            }}
          />
          <Flex
            bg="oceanBoatBlue"
            h="56px"
            flex={1}
            borderRadius={4}
            justifyContent="space-around"
            pos="relative"
          >
            {nav &&
              nav.map((n, x: number) => {
                return <NavLink x={x} title={n.title} tag={params.tag} />;
              })}

            {blogSearch && (
              <Flex
                zIndex={2}
                pos="absolute"
                right="1px"
                top="1px"
                bottom="1px"
                w={300}
                bg="rgba(255,255,255,0.98)"
                borderTopRightRadius={4}
                borderBottomRightRadius={4}
                alignItems="center"
                boxShadow="-10px 0 10px rgba(0,0,0,0.2)"
              >
                <Input
                  ref={searchRef}
                  autoFocus
                  placeholder="Search..."
                  display="flex"
                  borderWidth={0}
                  height="100%"
                  ms={4}
                  bg="transparent"
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      doSearch();
                    }
                  }}
                />
                <Link me={2} onClick={() => handleSearch(false)}>
                  <FaX
                    style={{
                      fontSize: '12px',
                    }}
                  />
                </Link>
              </Flex>
            )}
            <Button
              pos="relative"
              zIndex={1}
              background="rgba(0,0,0,0.3)"
              color="white"
              letterSpacing={0}
              fontSize={16}
              fontWeight={600}
              borderTopLeftRadius={0}
              borderBottomLeftRadius={0}
              height="100%"
              pe={4}
              ps={6}
              _hover={{
                background: 'rgba(0,0,0,0.5)',
              }}
              onClick={() => handleSearch(true)}
            >
              <FaMagnifyingGlass
                style={{
                  marginRight: '8px',
                }}
              />
            </Button>
          </Flex>
        </Flex>
        <Box
          display={{
            base: 'block',
            md: 'none',
          }}
        >
          <Flex gap={1} justifyContent="flex-start" flexWrap="wrap">
            {nav &&
              nav.map((n, x: number) => {
                return (
                  <Link
                    bg={params.tag === n.title ? '#002d5d' : 'oceanBoatBlue'}
                    onClick={() => jump(n.title, true)}
                    key={`le-nav-${x}`}
                    fontSize={13}
                    fontWeight={params.tag === n.title ? 700 : 500}
                    color="white"
                    p={1}
                    px={2}
                    borderRadius={4}
                    _hover={{
                      backgroundColor: 'leaseEndBlue',
                      color: 'white',
                    }}
                  >
                    {n.title}
                  </Link>
                );
              })}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Nav;
