import { Flex, Image, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { CarSold, LoanApproved } from '../../assets/Images';
import Widget from '../../components/LicensePlateOrVinInputEndorsement';
import Title from '../../components/Title';
import {
  ENDORSEMENTS,
  ENDORSEMENTS_EXPERTS,
  ENDORSEMENTS_NO_DEALER,
  ENDORSEMENTS_SAVED_MONEY,
  ENDORSEMENTS_SAVED_TIME,
} from '../../constants/urls';
import { fetchMarketingDeals } from '../../utils/globals';
import EndorsementIntro from './EndorsementIntro';
import EndorsementExpert from './EndorsementLike';
import EndorsementNoDealer from './EndorsementNoDealer';
import EndorsementSavedMoney from './EndorsementSavedMoney';
import EndorsementSavedTime from './EndorsementSavedTime';
import EndorsementsNav from './EndorsementsNav';

const Endorsements = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Flex flex={1} maxW={1920} flexDir="column">
        <EndorsementsNav />
        {pathname === ENDORSEMENTS && <EndorsementIntro />}
        {pathname === ENDORSEMENTS_NO_DEALER && <EndorsementNoDealer />}
        {pathname === ENDORSEMENTS_EXPERTS && <EndorsementExpert />}
        {pathname === ENDORSEMENTS_SAVED_MONEY && <EndorsementSavedMoney />}
        {pathname === ENDORSEMENTS_SAVED_TIME && <EndorsementSavedTime />}
        {pathname === ENDORSEMENTS && (
          <>
            <Flex
              alignItems="center"
              flexDir={{
                base: 'column-reverse',
                md: 'row',
              }}
              flex={1}
              w="100%"
              justifyContent="space-between"
              p={8}
              borderRadius={8}
              mb={4}
              gap={4}
            >
              <Flex
                bg="#FFF"
                flexDir="column"
                justifyContent="space-around"
                flex={1}
                gap={4}
                w="100%"
                height="100%"
              >
                <Flex
                  flexDir={{
                    base: 'column',
                    md: 'row',
                  }}
                  gap={{
                    base: 0,
                    md: 4,
                  }}
                  pt={{
                    base: 4,
                    md: 0,
                  }}
                  pb={{
                    base: 4,
                    md: 0,
                  }}
                  flex={1}
                  borderWidth={1}
                  justifyContent="center"
                  alignItems="center"
                  borderRadius={8}
                >
                  <Image
                    src={LoanApproved}
                    w={{
                      base: '200px',
                      md: '120px',
                    }}
                  />
                  <Title
                    letterSpacing="0.5px"
                    fontSize={44}
                    fontWeight={800}
                    color="black"
                    mt={4}
                    mb={0}
                  >
                    $800M+
                    <Text as="br" />
                    <Text textAlign="center" color="#787878" fontWeight={500} fontSize={16}>
                      in loans secured
                    </Text>
                  </Title>
                </Flex>
                <Flex
                  flexDir={{
                    base: 'column-reverse',
                    md: 'row',
                  }}
                  gap={{
                    base: 0,
                    md: 4,
                  }}
                  pt={{
                    base: 8,
                    md: 0,
                  }}
                  pb={{
                    base: 4,
                    md: 0,
                  }}
                  flex={1}
                  borderWidth={1}
                  justifyContent="center"
                  alignItems="center"
                  borderRadius={8}
                >
                  <Title
                    letterSpacing="0.5px"
                    fontSize={44}
                    fontWeight={800}
                    color="black"
                    mt={4}
                    mb={0}
                  >
                    {fetchMarketingDeals(true)}
                    <Text as="br" />
                    <Text textAlign="center" color="#787878" fontWeight={500} fontSize={16}>
                      leases ended!
                    </Text>
                  </Title>
                  <Image
                    src={CarSold}
                    w={{
                      base: '200px',
                      md: '120px',
                    }}
                  />
                </Flex>
              </Flex>
              <Flex
                w={{
                  base: '100%',
                  md: '45%',
                }}
                bg="#FFF"
                borderWidth={1}
                borderColor="#aaa"
                height={230}
                borderRadius={8}
                pb={5}
                px={5}
                pt={2}
              >
                <Widget />
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

export default Endorsements;
