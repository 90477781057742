import { Box, Flex, Text } from '@chakra-ui/react';

import { EndorsementsField } from '../../assets/Images';
import Widget from '../../components/LicensePlateOrVinInputEndorsement';
import ReviewLinks from '../Home/components/ReviewLinks';
import EndorsementHeading from './EndorsementHeading';
import EndorsementParagraph from './EndorsementParagraph';
import EndorsementVideo from './EndorsementVideo';
import EndorsementsSubnav from './EndorsementsSubnav';
import EndorsementsSubnavMobile from './EndorsementsSubnavMobile';

const EndorsementLike = () => {
  return (
    <>
      <EndorsementsSubnavMobile />
      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={{
          base: 0,
          md: 4,
        }}
        pos="relative"
        zIndex={1}
      >
        <EndorsementsSubnav label="skip the dealer" url="no-dealer" color="#3195ab" />
        <EndorsementsSubnav label="save time" url="saved-time" color="#032d5b" />
        <Flex
          bg="#599854"
          w={{
            base: '100%',
            lg: '60%',
          }}
          flexDir="column"
          py={10}
          ps={10}
          pe={5}
          pos="relative"
          zIndex={1}
        >
          <EndorsementHeading
            heading="Worked With Experts"
            color="#F4C53E"
            textAlign="left"
            mt={16}
          />
          <EndorsementParagraph
            color="white"
            paragraph="Buying out your lease can be a confusing process. But Lease End’s lease ending experts can make the process smoother than the ride in your new car."
          />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '40%',
          }}
        >
          <EndorsementVideo name="Montage-CustomerService" />
        </Flex>
        <EndorsementsSubnav label="save money" url="saved-money" color="#685495" />
      </Flex>

      <Flex
        flexDir={{
          base: 'column-reverse',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={8}
        pos="relative"
        zIndex={5}
      >
        <Flex
          justifyContent="center"
          w={{
            base: '100%',
            lg: '60%',
          }}
          mt={{
            base: 0,
            lg: '-72px',
          }}
          pos="relative"
          zIndex={2}
        >
          <Box
            w={{
              base: '100%',
              lg: '75%',
            }}
            bg="#FFF"
            p={3}
            borderRadius={8}
            boxShadow="0 0 10px rgba(0,0,0,0.18)"
            mt={{
              base: 8,
              lg: 0,
            }}
          >
            <Text fontSize="13px" color="#676767" textTransform="uppercase" fontWeight={700}>
              Get started with your License Plate or VIN
            </Text>
            <Widget btnLabel="SKIP THE DEALERSHIP" />
          </Box>
        </Flex>
      </Flex>

      <Flex
        justifyContent="center"
        w="100%"
        pos="relative"
        backgroundImage={EndorsementsField}
        backgroundSize="cover"
        backgroundPosition="bottom center"
        h="1000px"
        mt="-186px"
      >
        <Flex
          pos="absolute"
          top={64}
          flexDir="column"
          px={{
            base: 8,
            md: 24,
          }}
        >
          <EndorsementHeading
            textAlign="left"
            heading="talk directly to our idaho-based team."
            fontSize={{
              base: 36,
              md: 42,
            }}
            lineHeight={{
              base: 1.3,
            }}
            mb={8}
          />
          <EndorsementParagraph
            fontSize={{
              base: 16,
              md: 20,
            }}
            lineHeight={1.4}
            paragraph="When you call Lease End, you’ll talk directly to an actual, real-life person. Our Idaho-based customer service team is ready to take your lease paperwork off your hands and put money back in your pocket by finding you the best loan financing possible."
          />
        </Flex>
      </Flex>

      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        bg="#675395"
      >
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
        >
          <EndorsementVideo name="Jeanette-CustomerService" />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
          p={8}
        >
          <EndorsementHeading heading="people really like us." textAlign="left" color="white" />
          <EndorsementParagraph
            color="white"
            fontWeight={400}
            fontSize={22}
            lineHeight={1.6}
            mt={8}
            paragraph="At Lease End we take customer satisfaction seriously. We're all about making your experience quick, friendly, and stress-free. By the time you wrap up your lease buyout, we want our customers saying, “Wow, that was the easiest car purchase of my life!” - which is literally something literal customer Shonda S. said in a review...literally. Thanks, Shonda!"
          />
        </Flex>
      </Flex>
      <ReviewLinks m={0} pb={4} pt={8} background="#e0d8f1" />
    </>
  );
};

export default EndorsementLike;
