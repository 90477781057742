import { Image, Text, VStack } from '@chakra-ui/react';

import { BestMoneyLogo } from '../../../assets/Images';

const BestMoneyPartnershipBadge = () => {
  return (
    <VStack align="flex-start" spacing={1}>
      <Text color="black" fontSize="12px">
        In Partnership With
      </Text>
      <Image
        src={BestMoneyLogo}
        alt="Best Money Logo"
        h={{ base: '21px', md: '30px' }}
        w={{ base: '126px', md: '180px' }}
        mr="auto"
      />
    </VStack>
  );
};

export default BestMoneyPartnershipBadge;
