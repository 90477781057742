import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
} from '@chakra-ui/react';
import React from 'react';

const Item = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <AccordionItem borderWidth="1px" rounded="lg" mb={2} bg="white">
      <AccordionButton>
        <Box
          flex="1"
          textAlign="left"
          color="leaseEndBlue"
          fontSize={18}
          p={2}
          fontFamily="Monorale"
          fontWeight={600}
        >
          {title}
        </Box>
        <AccordionIcon color="leaseEndBlue" />
      </AccordionButton>
      <AccordionPanel pl={2} pt={4} color="leaseEndBlue" mx={6}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

const FrequentQuestions = () => {
  return (
    <Accordion allowToggle mt={4} maxW={{ base: 'none', lg: '680px' }}>
      <Item title="How long does the lease-end process take?">
        There are a few moving pieces involved with ending a car lease, and the total time to
        process your transaction can vary depending on who your auto lender is and what state you
        live in. However, it usually takes <b>less than 15 minutes</b> to get started - after that,
        our expert advisors take over and expedite the entire process for you.
      </Item>
      <Item title="What exactly is a lease payoff?">
        There are 3 ways you can end a car lease. You can turn the car back in to the dealership,{' '}
        <b>sell the car</b> , or <b>buy and keep the car</b>. If your lease is <b>bought out</b>,
        you're taking either the second or third option (selling the car, or buying it for
        yourself).
        <br />
        <br />
        Your leased car has a pre-determined <b>residual value</b> (the value of the car at the end
        of the lease). This value was established in your contract when you first agreed to lease,
        and it helps determine the <b>payoff amount</b> (the amount it would cost to purchase the
        vehicle so the manufacturer no longer holds the title). Every lender has their own specific
        way of calculating payoff amount, but generally speaking, it's more or less equal to the sum
        of the residual value plus any applicable taxes and fees.
        <br />
        <br />
        If you're leasing a car and decide that you want to keep it, you can <b>buy out</b> your
        lease, meaning you're purchasing the car and putting it in your name. If you decide you can
        make some money off your lease and want to sell it, someone else may be able to{' '}
        <b>purchase</b> the car and put it in their name. Either way, the car's new owner will have
        to pay the <b>payoff amount</b>, either up front or through financing, in order to transfer
        ownership of the car from the manufacturer.
      </Item>
      <Item title="I was told only my dealer can help me buy my leased vehicle, is this true?">
        <b>
          No, that's <i>not</i> true.
        </b>
        <br />
        <br />
        Manufacturers want customers to come back into the dealership at the end of their lease so
        they can try to convince a customer to let go of their current car and get back into another
        leased vehicle. With the recent increase in used car values, big leasing companies are
        desperately trying to hold on to the equity lease owners have in their vehicles. Unless it’s
        stated directly on your original lease contract (which is very rare), you do not have to go
        back to your dealer to buy out your lease. You may be told by dealers or your leasing
        company you have to work through them, but that's just the auto industry being shady.
      </Item>
      <Item title="What does Lease End do for me?">
        We can't change a lease-end date (or the inevitable need to make a big decision about it).
        What we can do is provide lease owners with the expert tools, technology, and advisory
        services to make a quick and informed decision about ending their car lease. Then, we assist
        our customers with <b>the entire lease payoff process</b> so they feel good about the
        outcome. This includes arranging <b>financing, titling</b> and <b>registration</b> (in most
        states), and offering extended <b>coverage</b> for to protect your “new” investment.
        <br />
        <br />
        Check out how the process works{' '}
        <b>
          <Link href="https://www.leaseend.com/how-it-works">here</Link>.
        </b>
      </Item>
      <Item title="How is my lease payoff price determined?">
        Your payoff amount is provided by the current lienholder and is calculated using the{' '}
        <b>residual value</b> for your car established in your original lease contract, plus{' '}
        <b>any remaining customer obligations</b>.
        <br />
        <br />
        When you work with us, you'll need to confirm your exact payoff amount with your lender, but
        don't worry - <b>we provide assistance</b> and instructions on how to do this.
      </Item>
      <Item title="Doesn't my leasing company technically own my leased vehicle?">
        you have a right to buy out your vehicle as long as your contract allows for it (most do)
        Your leasing company (or the manufacturer) does technically own your leased vehicle, but{' '}
        <b>you have a right to buy out your vehicle</b> as long as your contract allows for it (most
        do).
      </Item>
    </Accordion>
  );
};

export default FrequentQuestions;
