/* eslint-disable react/no-array-index-key */

/* eslint-disable no-console */
import { Flex, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import Loader from '../../components/Loader';
import Title from '../../components/Title';
import { BlogSearchTerm } from '../../helpers/Atom';
import { searchBlog } from '../Sanity/Data';
import { BlogPostProps } from '../Sanity/Model';
import SearchResult from './Result';

const Search = () => {
  const [blogSearchTerm] = useAtom(BlogSearchTerm);
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<BlogPostProps[] | null>(null);
  const handleSearch = async () => {
    setLoading(true);
    await searchBlog(blogSearchTerm)
      .then((data: BlogPostProps[] | null) => {
        setResults(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (blogSearchTerm) {
      handleSearch();
    }
  }, [blogSearchTerm]);
  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : results ? (
        <>
          <Flex maxW="1024px" flexDir="column" mx="auto" mt={8}>
            <Title textAlign="left" mb={4} fontSize={20} fontWeight={500}>
              Search Results for{' '}
              <Text as="span" fontWeight={700}>
                "{blogSearchTerm}"
              </Text>
            </Title>
            {results.map((post: BlogPostProps, x: number) => {
              // eslint-disable-next-line react/no-array-index-key
              return (
                <Flex>
                  <SearchResult post={post} key={`bp-${x}`} />
                </Flex>
              );
            })}
            ;
          </Flex>
        </>
      ) : (
        <>
          <Text>Ruh-roh</Text>
        </>
      )}
    </>
  );
};

export default Search;
