import { Flex, Image, Text } from '@chakra-ui/react';

import { EndorsementsLogoSimple } from '../../assets/Images';
import { ENDORSEMENTS } from '../../constants/urls';

const EndorsementsNav = () => {
  const home = () => {
    window.location.href = ENDORSEMENTS;
  };
  return (
    <>
      <Flex
        justifyContent="center"
        gap={4}
        my={4}
        flexDir="column"
        alignItems="center"
        onClick={() => home()}
      >
        <Image src={EndorsementsLogoSimple} w={280} />
        <Text
          color="#002d5d"
          fontWeight={700}
          textTransform="uppercase"
          fontSize={20}
          textAlign={{
            base: 'center',
          }}
        >
          Check out what real people have to say about working with Lease End
        </Text>
      </Flex>
    </>
  );
};

export default EndorsementsNav;
