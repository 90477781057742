import { Flex, Text } from '@chakra-ui/react';

import EndorsementsSubnav from './EndorsementsSubnav';

const EndorsementsSubnavMobile = () => {
  return (
    <>
      <Flex
        display={{
          base: 'flex',
          md: 'none',
        }}
        w="100%"
        pos="relative"
        zIndex={2}
      >
        <EndorsementsSubnav
          label="skip the dealer"
          url="no-dealer"
          color="#5093a7"
          flex={1}
          borderTopLeftRadius={8}
          mobile
        />
        <EndorsementsSubnav
          label={
            <>
              save
              <Text as="br" />
              time
            </>
          }
          url="saved-time"
          color="#032d5b"
          flex={1}
          mobile
        />
        <EndorsementsSubnav
          borderColor="#386b34"
          label={
            <>
              exceptional
              <Text as="br" />
              service
            </>
          }
          url="experts"
          color="#599754"
          flex={1}
          mobile
        />
        <EndorsementsSubnav
          label={
            <>
              save
              <Text as="br" />
              money
            </>
          }
          url="saved-money"
          color="#685495"
          flex={1}
          borderTopRightRadius={8}
          mobile
        />
      </Flex>
    </>
  );
};

export default EndorsementsSubnavMobile;
