import { Button, Flex, FlexProps } from '@chakra-ui/react';

import {
  ENDORSEMENTS_EXPERTS,
  ENDORSEMENTS_NO_DEALER,
  ENDORSEMENTS_SAVED_MONEY,
  ENDORSEMENTS_SAVED_TIME,
} from '../../constants/urls';
import EndorsementHeading from './EndorsementHeading';
import EndorsementParagraph from './EndorsementParagraph';

const PANEL_RADIUS = 8;
const PANEL_DATA: {
  cta: string;
  bgColor: string;
  bgImage: string;
  header: string;
  paragraph: string;
  url: string;
  props?: FlexProps;
}[] = [
  {
    bgColor: '#3195ab',
    bgImage: 'Montage-NoDealership-clean.png',
    header: 'skipped the dealer',
    paragraph:
      '    These drivers skipped the hassle of pushy dealerships by working with Lease End.',
    cta: 'skip the dealer',
    url: ENDORSEMENTS_NO_DEALER,
    props: {
      borderTopLeftRadius: PANEL_RADIUS,
      borderTopRightRadius: {
        base: PANEL_RADIUS,
        lg: 0,
      },
      borderBottomRadius: {
        base: PANEL_RADIUS,
        lg: 0,
      },
    },
  },
  {
    bgColor: '#032d5b',
    bgImage: 'Montage-Convenience-clean.png',
    header: 'saved time',
    paragraph:
      '    Lease End saved these drivers time by handling the lease ending process in a quick and stress-free way.',
    cta: 'save time',
    url: ENDORSEMENTS_SAVED_TIME,
    props: {
      borderTopRightRadius: PANEL_RADIUS,
      borderTopLeftRadius: {
        base: PANEL_RADIUS,
        lg: 0,
      },
      borderBottomRadius: {
        base: PANEL_RADIUS,
        lg: 0,
      },
    },
  },
  {
    bgColor: '#599754',
    bgImage: 'Montage-CustomerService-clean.png',
    header: 'worked with experts',
    paragraph:
      'Buying out your lease can be a confusing process. But Lease End’s lease ending experts can make the process smoother than the ride in your new car.',
    cta: 'work with experts',
    url: ENDORSEMENTS_EXPERTS,
    props: {
      borderBottomLeftRadius: PANEL_RADIUS,
      borderBottomRightRadius: {
        base: PANEL_RADIUS,
        lg: 0,
      },
      borderTopRadius: {
        base: PANEL_RADIUS,
        lg: 0,
      },
    },
  },
  {
    bgColor: '#685495',
    bgImage: 'PurpleMoney-clean.png',
    header: 'saved money',
    paragraph:
      'These drivers loved how much money they saved by buying out their lease with Lease End.',
    cta: 'save money',
    url: ENDORSEMENTS_SAVED_MONEY,
    props: {
      borderBottomRightRadius: PANEL_RADIUS,
      borderBottomLeftRadius: {
        base: PANEL_RADIUS,
        lg: 0,
      },
      borderTopRadius: {
        base: PANEL_RADIUS,
        lg: 0,
      },
    },
  },
];

const EndorsementIntro = () => {
  const jump = (panelUrl: string) => {
    window.location.href = panelUrl;
  };
  return (
    <>
      <Flex
        flex={1}
        flexWrap="wrap"
        gap={{
          base: '2px',
        }}
        mb={4}
      >
        {PANEL_DATA.map(({ cta, bgColor, bgImage, paragraph, header, props, url }) => {
          return (
            <>
              <Flex
                {...props}
                p={8}
                ps={12}
                pb={12}
                bgColor={bgColor}
                justifyContent="space-between"
                flexDir="row"
                w={{
                  base: 'calc(100% - 1px)',
                  md: 'calc(50% - 1px)',
                }}
                backgroundImage={`/leaseend-com-images/endorsements/${bgImage}`}
                backgroundSize="220px"
                backgroundRepeat="no-repeat"
                backgroundPosition="bottom right"
              >
                <Flex flexDir="column">
                  <EndorsementHeading
                    heading={header}
                    lineHeight={1.1}
                    fontSize={60}
                    fontWeight={800}
                    color="#F4C53E"
                    textAlign="left"
                    m={0}
                    p={0}
                  />
                  <Flex w="50%" flexDir="column">
                    <EndorsementParagraph
                      color="white"
                      fontWeight={500}
                      fontSize={17}
                      lineHeight={1.3}
                      px={0}
                      pt={4}
                      pb={8}
                      paragraph={paragraph}
                    />
                    <Button onClick={() => jump(`${url}`)} textTransform="uppercase">
                      {cta}
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </>
          );
        })}
      </Flex>
    </>
  );
};

export default EndorsementIntro;
